"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";

type WebsiteCardProps = {
  totalPrizeAmounts: { [key: string]: string };
};

export const WebsiteCard = (props: WebsiteCardProps) => {
  const { totalPrizeAmounts } = props;
  const [isMounted, setIsMounted] = useState(false);

  // Ensure the component only renders on the client side after mounting
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null; // Avoid rendering on the server

  const items = [
    {
      code: "CODE: Nickel",
      LEADERBOARD_TOTAL_AMOUNT: totalPrizeAmounts["clash"] || "0.00",
      custom_text: "",
      image: "/brands/ClashGG.gif",
      localImage: "/brands/ClashGG.gif",
      amount_image: "/currency/clash_currency_moving.gif",
      link: "clash",
      codeLink: "https://clash.gg/r/nickel",
    },
    {
      code: "CODE: Nickel",
      LEADERBOARD_TOTAL_AMOUNT: totalPrizeAmounts["cases"] || "0.00",
      custom_text: "",
      image: "/brands/cases.png",
      localImage: "/brands/ClashGG.gif",
      amount_image: "/currency/cases.svg",
      link: "cases",
      codeLink: "https://cases.gg/r/nickel",
    },
//    {
//      code: "CODE: Nickel",
//      LEADERBOARD_TOTAL_AMOUNT: totalPrizeAmounts["bcgames"] || "0.00",
//      custom_text: "CODE: Nickel",
//      image: "/brands/bcgames.webp",
//      localImage: "/brands/bcgames.webp",
//      amount_image: "/currency/bcgames.webp",
//     link: "bcgames",
//      codeLink: "https://bereavednickel7.com/r/bc",
//    },
  ];

  return (
    <section className="py-6 font-popping bg-indigo-600 bg-transparent text-white">
      <div className="container mx-auto px-3">
        <div className="flex flex-wrap justify-center items-stretch">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className="relative w-full sm:w-2/5 lg:w-1/5 p-3 flex">
                <a
                  href={item.link}
                  className="flex flex-col justify-center items-center space-y-1.5 h-full w-full no-underline"
                  rel="noopener noreferrer"
                >
                  <div className="bg-transparent dark:bg-transparent text-center transform transition duration-500 hover:scale-105 p-4.8 flex flex-col justify-center items-center space-y-1.5 h-full">
                    <div className="flex-grow flex items-center justify-center mb-2.4 h-1.6">
                      {item.image ? (
                        <Image
                          src={item.image}
                          alt="logo"
                          width={176}
                          height={38.4}
                          className="object-contain max-w-full"
                          priority
                        />
                      ) : item.localImage ? (
                        <Image
                          src={item.localImage}
                          alt="logo"
                          width={176}
                          height={38.4}
                          className="object-contain max-w-full"
                          priority
                        />
                      ) : (
                        <svg
                          className="w-38.4 h-9.6 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z" />
                        </svg>
                      )}
                    </div>
                    <div className="text-white whitespace-pre-wrap mb-0.8 relative flex justify-center items-center">
                      <Image
                        src={item.amount_image}
                        alt="amount icon"
                        width={16}
                        height={16}
                        className="mr-1.6"
                        priority
                      />
                      <span className="px-1.6">
                        {item.LEADERBOARD_TOTAL_AMOUNT}
                      </span>
                    </div>
                    <p className="animate-pulse text-red-500 dark:text-red-500 font-bold">
                      <a
                        href={item.codeLink}
                        target="_blank"
                        className="hover:underline"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {item.code}
                      </a>
                    </p>
                  </div>
                </a>
              </div>
              {index !== items.length - 1 && (
                <div className="flex items-center">
                  <div className="border-l-2 border-gray-300 dark:border-gray-700 h-full mx-3.2"></div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};
